var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-checkbox', _vm._b({
    staticClass: "grey-2--text font-weight-bold",
    attrs: {
      "label": "전체 동의합니다.",
      "color": "primary",
      "value": _vm.isAllSelected
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.toggleSelectedAll.apply(null, arguments);
      }
    }
  }, 'v-checkbox', _vm.attrs, false)), _c('v-divider', {
    staticClass: "border-2 grey-2 my-10 my-md-20"
  }), _vm._l(_vm.termsList, function (terms) {
    return _c('v-checkbox', _vm._b({
      key: terms._id,
      attrs: {
        "value": terms._id
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function () {
          return [_c('span', [_vm._v(" " + _vm._s(terms.subject) + " "), _c('span', {
            staticClass: "grey-a--text"
          }, [terms.necessary ? [_vm._v("(필수)")] : [_vm._v("(선택)")]], 2)])];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    }, 'v-checkbox', _vm.attrs, false));
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }