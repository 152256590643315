<template>
    <div>
        <div class="tit-wrap tit-wrap--centerline justify-center">
            <h2 class="tit">SNS 간편 로그인</h2>
        </div>

        <div>
            <kakao-login client_id="a1fe28b93668f4053dd4728e8e445f98" class="mt-10"><i class="icon icon-kakao mr-8"></i> 카카오로 3초 만에 시작하기</kakao-login>
            <!-- <naver-login client_id="" class="mt-10"><i class="icon icon-naver mr-8"></i>네이버로 3초 만에 시작하기</naver-login> -->
        </div>
    </div>
</template>

<script>
import kakaoLogin from "@/components/plugins/kakao/kakao-login.vue";
import NaverLogin from "@/components/plugins/naver/naver-login.vue";
export default {
    components: { kakaoLogin, NaverLogin },
};
</script>

<style></style>
