var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--join grey-f7"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--subtitle text-center"
  }, [_c('small', [_vm._v("join")]), _c('h2', {
    staticClass: "tit"
  }, [_vm._v("회원가입")])]), _c('v-card', {
    staticClass: "ma-auto pa-20 pa-lg-60",
    attrs: {
      "rounded": "xl",
      "elevation": "6",
      "max-width": "550"
    }
  }, [_c('div', {
    staticClass: "text-center mb-20 mb-md-40"
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-18 grey-2--text"
  }, [_vm._v("간편 회원가입으로 아이리뷰의 후기를 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("자유롭게 확인하세요!")])])]), _c('v-row', {
    staticClass: "row--medium"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "username",
      "placeholder": "아이디 (ex. userid@naver.com)",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inputs.username,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "username", $$v);
      },
      expression: "inputs.username"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "password",
      "placeholder": "비밀번호",
      "type": "password",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inputs.password,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "password", $$v);
      },
      expression: "inputs.password"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-20 mt-md-36"
  }, [_c('join-terms', {
    model: {
      value: _vm.inputs._terms,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "_terms", $$v);
      },
      expression: "inputs._terms"
    }
  })], 1), _c('div', {
    staticClass: "mt-20 mt-md-36"
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "block": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("가입하기")])], 1), _c('div', {
    staticClass: "mt-30 mt-md-50"
  }, [_c('join-sns')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }