var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-section"
  }, [_vm._l(_vm.termsList, function (terms) {
    return _c('div', {
      key: terms._id,
      staticClass: "join-row-wrap"
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('h2', {
      staticClass: "font-size-18 font-size-md-20 font-weight-medium"
    }, [_vm._v(_vm._s(terms.subject))])]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "9"
      }
    }, [_c('v-card', {
      staticClass: "join-box",
      attrs: {
        "tile": "",
        "outlined": "",
        "elevation": "0",
        "height": "180"
      }
    }, [_c('v-card-text', [terms.content ? _c('div', [_c('p', {
      staticClass: "textarea",
      attrs: {
        "name": "terms",
        "readonly": ""
      },
      domProps: {
        "innerHTML": _vm._s(terms.content)
      }
    })]) : _vm._e()])], 1), _c('div', {
      staticClass: "mt-10 mt-md-16"
    }, [_c('v-checkbox', {
      staticClass: "d-inline-flex",
      attrs: {
        "name": "terms",
        "value": terms._id,
        "label": "동의합니다",
        "hide-details": ""
      },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    })], 1)], 1)], 1)], 1);
  }), _c('v-divider', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "py-20 px-lg-20"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h2', {
    staticClass: "font-size-18 font-size-md-20 font-weight-medium"
  }, [_vm._v("전체선택")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-checkbox', {
    staticClass: "d-inline-flex",
    attrs: {
      "value": _vm.termsList.length == _vm.selected.length,
      "label": "모두 동의합니다",
      "hide-details": ""
    },
    on: {
      "click": function ($event) {
        _vm.selected = _vm.termsList.length == _vm.selected.length ? [] : _vm.termsList.map(function (terms) {
          return terms._id;
        });
      }
    }
  })], 1)], 1)], 1), _c('v-divider', {
    staticClass: "primary"
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }