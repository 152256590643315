<template>
    <div class="page-section">
        <div class="join-row-wrap" v-for="terms in termsList" :key="terms._id">
            <v-row>
                <v-col cols="12" md="3">
                    <h2 class="font-size-18 font-size-md-20 font-weight-medium">{{ terms.subject }}</h2>
                </v-col>
                <v-col cols="12" md="9">
                    <v-card tile outlined elevation="0" height="180" class="join-box">
                        <v-card-text>
                            <div v-if="terms.content">
                                <p class="textarea" name="terms" readonly v-html="terms.content"></p>
                            </div>
                        </v-card-text>
                    </v-card>
                    <div class="mt-10 mt-md-16">
                        <v-checkbox v-model="selected" name="terms" :value="terms._id" label="동의합니다" hide-details class="d-inline-flex"></v-checkbox>
                    </div>
                </v-col>
            </v-row>
        </div>

        <!-- 전체동의 -->
        <v-divider class="primary"></v-divider>
        <div class="py-20 px-lg-20">
            <v-row>
                <v-col cols="12" md="3">
                    <h2 class="font-size-18 font-size-md-20 font-weight-medium">전체선택</h2>
                </v-col>
                <v-col cols="12" md="9">
                    <v-checkbox :value="termsList.length == selected.length" @click="selected = (termsList.length == selected.length) ? [] : termsList.map((terms) => terms._id);" label="모두 동의합니다" hide-details class="d-inline-flex"></v-checkbox>
                </v-col>
            </v-row>
        </div>
        <v-divider class="primary"></v-divider>
    </div>
</template>

<script>
import api from "@/api";
export default {
    props: ["code"],
    data(){
        return {
            selected: [],
            termsList: [],

            filter: {
                code: this.$props.code || undefined
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { termsList } = await api.v1.terms.gets({ params: this.filter });
                this.termsList = termsList;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        check(){
            try{
                this.termsList.forEach((terms) => {
                    if(terms.necessary && this.selected.indexOf(terms._id) < 0){
                        throw new Error(`[${terms.subject}]에 동의해 주세요`);
                    }
                })

                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
    }
}
</script>
