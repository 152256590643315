<template>
    <client-page class="wrap--join grey-f7">
        <v-container>
            <div class="tit-wrap tit-wrap--subtitle text-center">
                <small>join</small>
                <h2 class="tit">회원가입</h2>
            </div>

            <v-card rounded="xl" elevation="6" max-width="550" class="ma-auto pa-20 pa-lg-60">
                <div class="text-center">
                    <i class="icon icon-check-mark ma-auto"></i>
                    <h3 class="font-size-16 font-size-md-18 mt-12">회원가입이 완료 되었습니다.</h3>
                </div>

                <!-- 하단버튼 -->
                <div class="mt-20 mt-md-36">
                    <v-btn to="/" x-large block rounded color="primary">메인으로</v-btn>
                </div>

                <div class="mt-10 mt-md-14">
                    <div class="row-bar-list-wrap">
                        <v-row justify="center" class="row-bar-list font-size-14">
                            <v-col cols="auto">
                                <v-btn x-small text color="grey-2" to="/login" class="h-auto pa-0 v-btn--none-active-bg">로그인</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-card>
        </v-container>
	</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default{
	components: {
		ClientPage,
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
