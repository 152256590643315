var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--join grey-f7"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--subtitle text-center"
  }, [_c('small', [_vm._v("join")]), _c('h2', {
    staticClass: "tit"
  }, [_vm._v("회원가입")])]), _c('v-card', {
    staticClass: "ma-auto pa-20 pa-lg-60",
    attrs: {
      "rounded": "xl",
      "elevation": "6",
      "max-width": "550"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('i', {
    staticClass: "icon icon-check-mark ma-auto"
  }), _c('h3', {
    staticClass: "font-size-16 font-size-md-18 mt-12"
  }, [_vm._v("회원가입이 완료 되었습니다.")])]), _c('div', {
    staticClass: "mt-20 mt-md-36"
  }, [_c('v-btn', {
    attrs: {
      "to": "/",
      "x-large": "",
      "block": "",
      "rounded": "",
      "color": "primary"
    }
  }, [_vm._v("메인으로")])], 1), _c('div', {
    staticClass: "mt-10 mt-md-14"
  }, [_c('div', {
    staticClass: "row-bar-list-wrap"
  }, [_c('v-row', {
    staticClass: "row-bar-list font-size-14",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto pa-0 v-btn--none-active-bg",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey-2",
      "to": "/login"
    }
  }, [_vm._v("로그인")])], 1)], 1)], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }