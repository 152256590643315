<template>
    <client-page class="wrap--join grey-f7">
        <v-container>
            <div class="tit-wrap tit-wrap--subtitle text-center">
                <small>join</small>
                <h2 class="tit">회원가입</h2>
            </div>

            <v-card rounded="xl" elevation="6" max-width="550" class="ma-auto pa-20 pa-lg-60">
                <div class="text-center mb-20 mb-md-40">
                    <strong class="font-size-16 font-size-md-18 grey-2--text">간편 회원가입으로 아이리뷰의 후기를 <br class="d-md-none"/><span class="primary--text">자유롭게 확인하세요!</span></strong>
                </div>

                <v-row class="row--medium">
                    <v-col cols="12">
                        <v-text-field v-model="inputs.username" name="username" placeholder="아이디 (ex. userid@naver.com)" outlined hide-details />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="inputs.password" name="password" placeholder="비밀번호" type="password" outlined hide-details />
                    </v-col>
                </v-row>

                <div class="mt-20 mt-md-36">
                    <join-terms v-model="inputs._terms" />
                </div>

                <div class="mt-20 mt-md-36">
                    <v-btn x-large block rounded color="primary" @click="submit">가입하기</v-btn>
                </div>

                <div class="mt-30 mt-md-50">
                    <join-sns />
                </div>
            </v-card>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import TermsOfAgreements from "@/components/client/join/terms-of-agreements.vue";
import api from "@/api";
import JoinTerms from "@/components/client/join/join-terms.vue";
import JoinSns from "@/components/client/join/join-sns.vue";
import CryptoAES from "@/plugins/crypto-aes";
import { USER_TYPES } from "@/assets/variables";

export default {
    components: {
        ClientPage,
        TermsOfAgreements,
        JoinTerms,
        JoinSns,
    },
    data: () => ({
        inputs: {
            code: USER_TYPES.PERSON.value,
            _terms: [],
            username: null,
            password: null,
        },
    }),
    methods: {
        validate() {
            try {
                if (!this.inputs.username) throw new Error("아이디를 입력해주세요.");
                if (!/[\S]+@[\S]+\.[\S]{2,3}/.test(this.inputs.username)) throw new Error("이메일 형식이 잘못되었습니다");
                if (!this.inputs.password) throw new Error("비밀번호를 입력해주세요.");

                return true;
            } catch (error) {
                console.error(error);
                alert(error.message);

                return false;
            }
        },
        async submit() {
            const go = this.validate();
            if (!go) return;

            await api.v1.users.post({ ...this.inputs, password: CryptoAES.encrypt(this.inputs.password) });
            this.$router.push("/join/complete");
        },
    },
};
</script>
