<template>
    <div>
        <v-checkbox label="전체 동의합니다." color="primary" class="grey-2--text font-weight-bold" :value="isAllSelected" v-bind="attrs" @click.prevent="toggleSelectedAll" />
        <v-divider class="border-2 grey-2 my-10 my-md-20" />
        <v-checkbox v-model="selected" v-for="terms in termsList" :key="terms._id" :value="terms._id" v-bind="attrs">
            <template #label>
                <span>
                    {{ terms.subject }}
                    <span class="grey-a--text">
                        <template v-if="terms.necessary">(필수)</template>
                        <template v-else>(선택)</template>
                    </span>
                </span>
            </template>
        </v-checkbox>
    </div>
</template>

<script>
import api from "@/api";
const attrs = {
    "hide-details": true,
    class: "",
};
export default {
    props: {
        code: { type: String, default: null },
    },
    data: () => ({
        selected: [],
        termsList: [],
        attrs,
    }),
    computed: {
        isAllSelected() {
            return this.termsList?.length == this.selected?.length;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        selected() {
            this.$emit("input", this.selected);
        },
    },
    methods: {
        async init() {
            const { code } = this;
            const { termsList } = await api.v1.terms.gets({ params: { code } });
            this.termsList = termsList;
        },
        toggleSelectedAll() {
            if (this.isAllSelected) this.selected = [];
            else this.selected = this.termsList.map(({ _id }) => _id);
        },
    },
};
</script>

<style scoped>
>>>.v-input--checkbox.grey-2--text .v-label{
    color: #222 !important;
}
>>>.v-input--is-label-active .v-icon{
    color: var(--v-primary-base) !important;
}
</style>