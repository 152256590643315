var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', [_c('kakao-login', {
    staticClass: "mt-10",
    attrs: {
      "client_id": "a1fe28b93668f4053dd4728e8e445f98"
    }
  }, [_c('i', {
    staticClass: "icon icon-kakao mr-8"
  }), _vm._v(" 카카오로 3초 만에 시작하기")])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap tit-wrap--centerline justify-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("SNS 간편 로그인")])]);

}]

export { render, staticRenderFns }